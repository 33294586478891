import Link from '@/components/Link';
import Container from '@/components/Container';

export default function NotFoundPage() {
  return (
    <div className="w-full">
      <Container>
        <div className="py-10 lg:py-20 space-y-6 text-lg">
          <h2 className="text-xl lg:text-2xl xl:text-4xl font-semibold lg:tracking-tight">
            No pudimos encontrar la página que buscaba.
          </h2>
          <p>Esto se debe a que:</p>
          <ul>
            <li className="flex items-start space-x-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 mt-0.5 text-red-600"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
              <div className="flex-1">
                Hay un error en la URL ingresada en su navegador web. Compruebe
                la URL y vuelva a intentarlo.
              </div>
            </li>
            <li className="flex items-start space-x-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 mt-0.5 text-red-600"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
              <div className="flex-1">
                La página que busca se ha movido o eliminado.
              </div>
            </li>
          </ul>
          <p>
            Puede volver a nuestra página de inicio haciendo clic{' '}
            <Link className="font-medium underline" href="/">
              aquí
            </Link>
            .
          </p>
        </div>
      </Container>
    </div>
  );
}
